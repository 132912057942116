import './App.css';
import { Home } from './pages/Home/Home';
import { Browse } from './pages/Browse/Browse';
import { Route, Routes } from 'react-router-dom';
import { Properties } from './pages/Properties/properties';
import { Commercial } from './pages/Commercial/Commercial';
import { Students } from './pages/Students/Students';
import { Contactus } from './pages/Contactus/contactus';
import { Ebook } from './pages/Ebook/Ebook';
import { Login } from './pages/Login/login';
import { Team } from './pages/Team/team';
import { GeneralApllication } from './pages/GeneralAplication/GeneralApllication';
import { StudentApplication } from './pages/StudentApplication/StudentApplication';
import { Showing } from './pages/Showing/Showing';
import Listing from './pages/Listing/listing';

import { Tenent } from './pages/tenent/Tenent';
import { Landlords } from './pages/Landlords/Landlords';
import { ServiceRequest } from './pages/serviceRequest/ServiceRequest';
import { ConfirmProperty } from './pages/confirmProperty/ConfirmProperty';
import { TenentHandbook } from './pages/TenentHandbook/TenentHandbook';
import { BecomeATenent } from './pages/BecomeATenent/BecomeATenent';
import { TenentReview } from './pages/TenentReview/TenentReview';
import { PropertyManagement } from './pages/PropertyManagement/PropertyManagement';
import { TenentPlacement } from './pages/TenantPlacement/TenantPlacement';
import { TenentScreening } from './pages/TenantScreening/TenantScreening';
import { Maintenance } from './pages/Maintenance _Tenent/Maintenance ';
import { LegalServices } from './pages/LegalServices/LegalServices';
import { CustomerReviews } from './pages/CustomerReviews/CustomerReviews';
import { SubletAgreement } from './pages/SubletAgreement/SubletAgreement';
import { TenentTenancy } from './pages/TenantNoticeTinancy/TenantNoticeTinancy';
import { ScheduleShowing } from './pages/ScheduleShowing/ScheduleShowing';
import PropertyView from './pages/SinglePropertyView/PropertyView';
import Thankyou from './pages/Thankyou/Thankyou';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="browse" element={<Browse />} />
        <Route path="properties" element={<Properties />} />
        <Route path="commercial" element={<Commercial />} />
        <Route path="student" element={<Students />} />
        <Route path="properties" element={<Properties />} />
        <Route path="contactus" element={<Contactus />} />
        <Route path="ebook" element={<Ebook />} />
        <Route path="ebook/thankyou" element={<Thankyou />} />
        <Route path="login" element={<Login />} />
        <Route path="team" element={<Team />} />
        <Route path="listing" element={<Listing />} />
        <Route path="listing/:id" element={<PropertyView />} />
        <Route path="genaral-application" element={<GeneralApllication />} />
        <Route path="student-application" element={<StudentApplication />} />
        <Route path="Showing" element={<Showing />} />
        <Route path="Tenent" element={<Tenent />} />
        <Route path="Landlords" element={<Landlords />} />
        <Route path="ServiceRequest" element={<ServiceRequest />} />
        <Route path="ConfirmProperty/:id" element={<ConfirmProperty />} />
        <Route path="TenentHandbook" element={<TenentHandbook />} />
        <Route path="BecomeATenent" element={<BecomeATenent />} />
        <Route path="TenentReview" element={<TenentReview />} />
        <Route path="PropertyManagement" element={<PropertyManagement />} />
        <Route path="TenentPlacement" element={<TenentPlacement />} />
        <Route path="TenentScreening" element={<TenentScreening />} />
        <Route path="Maintenance" element={<Maintenance />} />
        <Route path="LegalServices" element={<LegalServices />} />
        <Route path="CustomerReviews" element={<CustomerReviews />} />
        <Route path="SubletAgreement" element={<SubletAgreement />} />
        <Route path="TenentTenancy" element={<TenentTenancy />} />
        <Route path="ScheduleShowing" element={<ScheduleShowing />} />
      </Routes>
    </>
  );
}

export default App;
