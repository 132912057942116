import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config.json'

import './Landlords.css';


export const Landlords = () => {
    const handleCaptcha = (val) => {
        console.log(val)
    }
    return (
        <>
            <KWNavbar></KWNavbar>

            {/* landlords start */}

            <div className="container mt-5 pt-5 mb-5">
                <div className="mt-5"></div>
                <div className="col-sm-12 col-md-11 mx-auto">
                    <div className="w-100 landlords-main-head">
                        <div className="landlords-cHeader">Property owners learn more about us!</div>
                        <div className="landlords-cPara">
                            If you are a property owner and are interested in learning more about our property management services please fill out the form below and we will provide you with additional information about our services! You can also read our
                            <a>Frequently Asked Questions</a>
                            , or call or email anytime if you have additional questions or comments.
                        </div>
                        <div className="landlords-cContacth">
                            <div className="landlords-cContacth1">Phone: 519-954-8082</div>
                            <div className="landlords-cContacth2">Email: <a href="#">kwp@kwproperty.com</a></div>
                        </div>
                    </div>
                    <div className="landlord-form-main">
                        <div className="landlords-form">
                            <div className="landlords-input">
                                <label htmlFor="PropertyType" className='landlords-form-label'>Property Type*</label>
                                <select name="PropertyType" id="PropertyType" className='required-number required-number2 landlords-form-select' >
                                    <option value="Apartment">Apartment</option>
                                    <option value="Apartment">Commercial</option>
                                    <option value="Apartment">House</option>
                                    <option value="Apartment">Townhouse</option>
                                    <option value="Apartment">Semi-Detached</option>
                                </select>
                            </div>
                            <div className="landlords-input mt-4">
                                <label htmlFor="StreetAddress" className='landlords-form-label'>Street Address*</label>
                                <input type="text" name="StreetAddress" id="StreetAddress" className='required-number required-number2' />
                            </div>
                            <div className="landlords-input mt-4">
                                <label htmlFor="City" className='landlords-form-label'>City*</label>
                                <input type="text" name="City" id="City" className='required-number required-number2' />
                            </div>
                            <div className="landlords-input mt-4">
                                <label htmlFor="Province" className='landlords-form-label'>Province*</label>
                                <input type="text" name="Province" id="Province" className='required-number required-number2' />
                            </div>
                            <div className="landlords-input mt-4">
                                <label htmlFor="PostalCode" className='landlords-form-label'>Postal Code*</label>
                                <input type="text" name="PostalCode" id="PostalCode" className='required-number required-number2' />
                            </div>
                            <div className="landlords-input mt-4 pt-2">
                                <div className='dummyhello' style={{ opacity: 0 }}>hello</div>
                                <div className="Property-Currently-div">
                                    <div className='landlords-form-label2'>Is The Property Currently Vacant?*</div>
                                    <div className="mt-2 d-flex">
                                        <div class="form-check me-3">
                                            <input type="radio" class="form-check-input" id="radio1" name="optradio" value="option1" style={{ cursor: "pointer" }} />
                                            <label class="form-check-label" for="radio1" style={{ cursor: "pointer" }}>Yes</label>
                                        </div>
                                        <div class="form-check me-3">
                                            <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2" style={{ cursor: "pointer" }} />
                                            <label class="form-check-label" for="radio2" style={{ cursor: "pointer" }}>Tenant Occupied</label>
                                        </div>
                                        <div class="form-check me-2">
                                            <input type="radio" class="form-check-input" id="radio2" name="optradio" value="option2" style={{ cursor: "pointer" }} />
                                            <label class="form-check-label" for="radio2" style={{ cursor: "pointer" }}>Owner Occupied</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="landlords-input mt-4">
                                <label htmlFor="FirstName" className='landlords-form-label'>First Name*</label>
                                <input type="text" name="FirstName" id="FirstName" className='required-number required-number2' />
                            </div>
                            <div className="landlords-input mt-4">
                                <label htmlFor="LastName" className='landlords-form-label'>Last Name*</label>
                                <input type="text" name="LastName" id="LastName" className='required-number required-number2' />
                            </div>
                            <div className="landlords-input mt-4">
                                <label htmlFor="Phone" className='landlords-form-label'>Phone*</label>
                                <input type="text" name="Phone" id="Phone" className='required-number required-number2' />
                            </div>
                            <div className="landlords-input mt-4">
                                <label htmlFor="EmailAddress" className='landlords-form-label'>Email Address*</label>
                                <input type="text" name="EmailAddress" id="EmailAddress" className='required-number required-number2' />
                            </div>
                            <div className="landlords-input mt-4 pt-2">
                                <div style={{ opacity: 0 }}>hello</div>
                                <div className=''>
                                    <div className='landlords-form-label2'>Best Time To Call*</div>
                                    <select name="BestTimeToCall" id="BestTimeToCall" className='required-number required-number2 landlords-form-select mt-2' >
                                        <option value="Anytime">Anytime</option>
                                        <option value="Morning">Morning</option>
                                        <option value="Afternoon">Afternoon</option>
                                        <option value="Evening">Evening</option>
                                    </select>
                                </div>
                            </div>
                            <div className="landlords-input mt-4 pt-2">
                                <div style={{ opacity: 0 }}>hello</div>
                                <div className=''>
                                    <div className='landlords-form-label2'>How Many Rental Properties Do You Own?*</div>
                                    <select name="HowManyRental" id="HowManyRental" className='required-number required-number2 landlords-form-select mt-2' >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="More than 5">More than 5</option>
                                    </select>
                                </div>
                            </div>
                            <div className="landlords-input mt-4 pt-2">
                                <div style={{ opacity: 0 }}>hello</div>
                                <div className=''>
                                    <div className='landlords-form-label2'>Basic property details & any questions/comments*</div>
                                    <textarea name="Property-Currently-textarea" id="Property-Currently-textarea" className='required-number required-number2 mt-2 Property-Currently-textarea'></textarea>
                                </div>
                            </div>
                            <div className="landlords-input mt-4 pt-2">
                                <div style={{ opacity: 0 }}>hello</div>
                                <div className=''>
                                    <div className='landlords-form-label2 mb-2'>
                                        To protect this website from spam, please check the box below.
                                    </div>
                                    <div>
                                        <ReCAPTCHA
                                            sitekey={config.reCaptchaKey}
                                            onChange={handleCaptcha}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="landlords-input mt-4 pt-2">
                                <div style={{ opacity: 0 }}>hello</div>
                                <div className=''>
                                    <button type='button' className='Property-Currently-button'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* landlords end */}
            <KWFooter></KWFooter>
        </>
    )
}