import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import KWPropertyCard from '../propertycard/propertycard'
import img from '../../assets/no-image.png'
import { LineWave } from 'react-loader-spinner';
import './propertysection.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { BsChevronDoubleLeft } from 'react-icons/bs';
import { BsChevronDoubleRight } from 'react-icons/bs';


import config from '../../config.json'
import { useNavigate } from 'react-router-dom';

const KWPropertySection = () => {
  const [propertyData, setPropertyData] = useState([]);
  const sliderRef = useRef(null);
  const navigate = useNavigate()

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // console.log('Rental : ' + config.apiUrl + '/v1/rentals')

        const response = await fetch(config.apiUrl + '/v1/rentals/units/listings', {
         
        });
        if (response.ok) {
          const data = await response.json();
          setPropertyData(data);
        } else {
          throw new Error('Error fetching property data');
        }
      } catch (error) {
        console.error('Error fetching property data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {/* {propertyData.length > 0 && */}
      <Container className='mb-4 border-1'>
        <h1 className="mb-2">Rental <span className="blue">Properties</span></h1>
        <h4 className='mb-3'>TRUSTABLE, EASY, FURNISHED</h4>
        {
          propertyData.length > 3 ? (
            <>
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                ref={sliderRef}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1
                  },
                  768: {
                    slidesPerView: 2
                  },
                  1024: {
                    slidesPerView: 3
                  }
                }}
              >
                {
                  propertyData.map((property, index) => (
                    <SwiperSlide key={index}>
                      <KWPropertyCard img={img} currentProperty={property} />
                    </SwiperSlide>
                  ))
                }

              </Swiper>
              <div className='d-flex w-100 justify-content-around'>
                <BsChevronDoubleLeft color='blue' size={20} className="prev-arrow prevBTN" onClick={handlePrev} />
                <BsChevronDoubleRight color='blue' size={20} className="next-arrow nextBTN" onClick={handleNext} />
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              <LineWave
                height="200px"
                width="300px"
                color="#0586f0"
                ariaLabel="line-wave"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                firstLineColor=""
                middleLineColor=""
                lastLineColor=""
              />
            </div>
          )
        }

        <Button variant='primary' onClick={() => navigate('/properties')} className='mx-auto d-block mt-3'>View more properties</Button>
      </Container>
        {/* } */}
    </>
  )
}

export default KWPropertySection